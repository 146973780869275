import { Disclosure, Popover, Transition } from '@headlessui/react';
import { ChevronDownIcon, MapIcon } from '@heroicons/react/20/solid';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { Logo } from '@/components/utils/Logo';
import { NavLink } from '@/components/utils/NavLink';
import { useAuth } from '@/contexts/AuthContext';
import { LOCALES } from '@/libs/app/const';
import clsx from 'clsx';

const DocumentIcon = dynamic(() =>
  import('@heroicons/react/20/solid').then((module) => module.DocumentIcon),
);
const Button = dynamic(() =>
  import('@/components/utils/Button').then((module) => module.Button),
);
const FaRobot = dynamic(() => import('@/components/utils/FaRobot'));
const FaStethoscope = dynamic(() => import('@/components/utils/FaStethoscope'));
const SideBySideIcon = dynamic(() =>
  import('@/components/utils/SideBySideIcon'),
);
const ArticlesIcon = dynamic(() => import('@/components/utils/ArticlesIcon'));

const Dropdown = dynamic(() => import('./Dropdown'));

const products = [
  {
    name: 'chatbot',
    description: 'feature_1_content',
    href: '/chat',
    icon: FaRobot,
  },
  {
    name: 'diagnose_btn',
    description: 'feature_2_content',
    href: '/diagnose',
    icon: FaStethoscope,
  },
  {
    name: 'side_by_side',
    description: 'feature_3_content',
    href: '/side-by-side',
    icon: SideBySideIcon,
  },
];

const resources = [
  {
    name: 'Community  ',
    href: '/community',
    description: 'Find shared diagnosis reports in the community',
    icon: DocumentIcon,
  },
  {
    name: 'Blog',
    href: '/blog',
    description:
      'Learn more about Medical Chat by reading through our latest articles!',
    icon: ArticlesIcon,
  },
];

const navigation = [
  { name: 'price', href: '/pricing' },
  { name: 'Build Your Chatbot', href: 'https://www.chat-data.com' },
];

function MobileNavLink({ href, children, ...rest }) {
  return (
    <Link
      href={href}
      {...rest}
      className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
    >
      {children}
    </Link>
  );
}

function MobileNavIcon({ open }) {
  return (
    <svg
      aria-hidden="true"
      className="h-3.5 w-3.5 overflow-visible stroke-slate-700"
      fill="none"
      strokeWidth={2}
      strokeLinecap="round"
    >
      <path
        d="M0 1H14M0 7H14M0 13H14"
        className={clsx(
          'origin-center transition',
          open && 'scale-90 opacity-0',
        )}
      />
      <path
        d="M2 2L12 12M12 2L2 12"
        className={clsx(
          'origin-center transition',
          !open && 'scale-90 opacity-0',
        )}
      />
    </svg>
  );
}

function MobileNavigation({ showSignup, showSignin }) {
  const { t, i18n } = useTranslation();
  const { currentUser } = useAuth();
  return (
    <Popover>
      <Popover.Button
        className="relative z-10 flex h-8 w-8 items-center justify-center ui-not-focus-visible:outline-none"
        aria-label="Toggle Navigation"
      >
        {({ open }) => <MobileNavIcon open={open} />}
      </Popover.Button>
      <Transition.Root>
        <Transition.Child
          as={Fragment}
          enter="duration-150 ease-out"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="duration-150 ease-in"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Popover.Overlay className="fixed inset-0 bg-slate-300/50" />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="duration-150 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel
            as="div"
            className="absolute z-50 inset-x-0 top-full mt-4 flex origin-top flex-col rounded-2xl bg-white p-4 text-lg tracking-tight text-slate-900 shadow-xl ring-1 ring-slate-900/5"
          >
            <Disclosure as="div" className="-mx-3">
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                    Product
                    <ChevronDownIcon
                      className={clsx(
                        open ? 'rotate-180' : '',
                        'h-5 w-5 flex-none',
                      )}
                      aria-hidden="true"
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel className="mt-2 space-y-2">
                    {products.map((item) => (
                      <Disclosure.Button
                        key={t(item.name)}
                        as="a"
                        href={item.href?.trim()}
                        className="group/item flex w-full items-start gap-2 rounded-md p-2 transition-colors duration-300 ease-in-out hover:bg-zinc-100"
                        title={t(item.name)}
                      >
                        <div className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 transition-transform duration-500 ease-in-out group-hover:bg-white group-hover:scale-125">
                          <span className="sr-only">{t(item.name)}</span>
                          <item.icon
                            className="h-6 w-6 text-gray-600 group-hover:text-indigo-600"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="flex flex-col items-start gap-0.5">
                          <span className="text-sm font-semibold leading-6 text-gray-700  group-hover/item:text-zinc-900">
                            {t(item.name)}
                          </span>
                          <span className="min-w-[260px] text-xs leading-5 text-zinc-500 group-hover/item:text-zinc-700">
                            {t(item.description)}
                          </span>
                        </div>
                      </Disclosure.Button>
                    ))}
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>

            <Disclosure as="div" className="-mx-3">
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                    {t('Resources')}
                    <ChevronDownIcon
                      className={clsx(
                        open ? 'rotate-180' : '',
                        'h-5 w-5 flex-none',
                      )}
                      aria-hidden="true"
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel className="mt-2 space-y-2">
                    {resources.map((item) => (
                      <Disclosure.Button
                        key={t(item.name)}
                        as="a"
                        href={item.href?.trim()}
                        className="group/item flex w-full items-start gap-2 rounded-md p-2 transition-colors duration-300 ease-in-out hover:bg-zinc-100"
                        title={t(item.name)}
                      >
                        <div className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 transition-transform duration-500 ease-in-out group-hover:bg-white group-hover:scale-125">
                          <span className="sr-only">{t(item.name)}</span>
                          <item.icon
                            className="h-6 w-6 text-gray-600 group-hover:text-indigo-600"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="flex flex-col items-start gap-0.5">
                          <span className="text-sm font-semibold leading-6 text-gray-700  group-hover/item:text-zinc-900">
                            {t(item.name)}
                          </span>
                          <span className="min-w-[260px] text-xs leading-5 text-zinc-500 group-hover/item:text-zinc-700">
                            {t(item.description)}
                          </span>
                        </div>
                      </Disclosure.Button>
                    ))}
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>

            <MobileNavLink href="/pricing">{t('price')}</MobileNavLink>
            <MobileNavLink
              href="https://www.chat-data.com/careers"
              target="_blank"
            >
              {t('Careers')}
            </MobileNavLink>
            <MobileNavLink href="https://discord.gg/2XmKDHpMZn" target="_blank">
              {t('Discord')}
            </MobileNavLink>
            <MobileNavLink href="https://www.chat-data.com">
              {t('Build Your Chatbot')}
            </MobileNavLink>
            <hr className="m-2 border-slate-300/40" />
            <label htmlFor="locale-select" className="sr-only">
              Locale Select
            </label>
            <select
              id="locale-select"
              className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
              onChange={(e) => {
                i18n.changeLanguage(e.target.value);
              }}
              value={i18n.language}
            >
              {Object.entries(LOCALES).map(([key, value], index) => (
                <option key={index} value={key}>
                  {t(value)}
                </option>
              ))}
            </select>
            {showSignup && !currentUser && (
              <MobileNavLink href="/signup">{t('sign_up')}</MobileNavLink>
            )}
            {showSignin && !currentUser && (
              <MobileNavLink href="/signin">{t('sign_in')}</MobileNavLink>
            )}
            {currentUser && (
              <Dropdown name={currentUser.name} email={currentUser.email} />
            )}
          </Popover.Panel>
        </Transition.Child>
      </Transition.Root>
    </Popover>
  );
}

export function NavHeader({ showSignup = true, showSignin = true }) {
  const { t, i18n } = useTranslation();
  const { currentUser } = useAuth();
  return (
    <header className="py-10">
      <div className="mx-auto max-w-screen-2xl px-2">
        <nav className="relative flex justify-between">
          <div className="flex items-center lg:gap-x-2">
            <Logo className="h-10 w-auto" />
            <Popover.Group className="hidden lg:flex lg:gap-x-2">
              <Popover className="relative">
                <Popover.Button className="flex items-center gap-x-1 inline-block leading-6 rounded-lg px-2 py-1 text-sm font-semibold text-gray-900 hover:bg-slate-100">
                  {t('Products')}
                  <ChevronDownIcon
                    className="h-5 w-5 flex-none text-gray-400"
                    aria-hidden="true"
                  />
                </Popover.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <div className="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5">
                    <div className="p-4">
                      {products.map((item) => (
                        <div
                          key={item.name}
                          className="group relative flex gap-x-2 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50"
                        >
                          <div className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                            <item.icon
                              className="h-6 w-6 text-gray-600 group-hover:text-indigo-600"
                              aria-hidden="true"
                            />
                          </div>
                          <div className="flex-auto">
                            <Link
                              href={item.href}
                              className="block font-semibold text-gray-900"
                            >
                              {t(item.name)}
                              <span className="absolute inset-0" />
                            </Link>
                            <p className="mt-1 text-gray-600">
                              {t(item.description)}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </Transition>
              </Popover>

              <Popover className="relative">
                <Popover.Button className="flex items-center gap-x-1 inline-block leading-6 rounded-lg px-2 py-1 text-sm font-semibold text-gray-900 hover:bg-slate-100">
                  {t('Resources')}
                  <ChevronDownIcon
                    className="h-5 w-5 flex-none text-gray-400"
                    aria-hidden="true"
                  />
                </Popover.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <div className="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5">
                    <div className="p-4">
                      {resources.map((item) => (
                        <div
                          key={item.name}
                          className="group relative flex gap-x-2 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50"
                        >
                          <div className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                            <item.icon
                              className="h-6 w-6 text-gray-600 group-hover:text-indigo-600"
                              aria-hidden="true"
                            />
                          </div>
                          <div className="flex-auto">
                            <Link
                              href={item.href}
                              className="block font-semibold text-gray-900"
                            >
                              {t(item.name)}
                              <span className="absolute inset-0" />
                            </Link>
                            <p className="mt-1 text-gray-600">
                              {t(item.description)}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </Transition>
              </Popover>

              {navigation.map((item) => (
                <NavLink key={item.name} href={item.href}>
                  {t(item.name)}
                </NavLink>
              ))}
            </Popover.Group>
          </div>
          <div className="flex items-center gap-x-2 lg:gap-x-2">
            <div className="hidden lg:block">
              <label htmlFor="locale-select" className="sr-only">
                Locale Select
              </label>
              <select
                id="locale-select"
                className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                onChange={(e) => {
                  i18n.changeLanguage(e.target.value);
                }}
                value={i18n.language}
              >
                {Object.entries(LOCALES).map(([key, value], index) => (
                  <option key={index} value={key}>
                    {t(value)}
                  </option>
                ))}
              </select>
            </div>
            <div className="hidden lg:block">
              <NavLink href="https://www.chat-data.com/careers" target="_blank">
                Careers
              </NavLink>
            </div>
            <div className="hidden lg:block">
              <NavLink
                className="my-auto text-sm font-semibold leading-6 text-gray-900 px-2 py-1 rounded hover:bg-slate-100"
                href="https://chat-data.canny.io/"
                target="_blank"
                title="Medical Chat Roadmap"
              >
                <MapIcon className="w-5 h-5 inline-block" /> {t('Roadmap')}
              </NavLink>
            </div>
            {showSignup && !currentUser && (
              <Button href="/signup" color="blue">
                <span>{t('Get started')}</span>
              </Button>
            )}
            {showSignin && !currentUser && (
              <div className="hidden lg:block">
                <NavLink href="/signin">
                  {t('sign_in')} <span aria-hidden="true">&rarr;</span>
                </NavLink>
              </div>
            )}
            {currentUser && (
              <div className="hidden lg:block">
                <Dropdown name={currentUser.name} email={currentUser.email} />
              </div>
            )}
            <div className="-mr-1 lg:hidden">
              <MobileNavigation
                showSignup={showSignup}
                showSignin={showSignin}
              />
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
}
